import request from "@/utils/request"

// 支付信息查询
export const getamount = (params) => {

  return request({
      method: "get",
      params: params,
      url: "/QBaoHan/get_amount",
  });
};
// 网页二维码支付
export const paysend = (params) => {

    return request({
        method: "get",
        params: params,
        // url: "/tripartite/mobile/pay/paysend",
        //  url: "/tripartite/mobile/pay/getpaycode",
        url: "/pay/singcode_paysend",
    });
};
//支付回调
export const paylist = (params) => {

    return request({
        method: "get",
        params: params,
        url: "/pay/pay_status",
    });
};
// // 网页二维码支付
// export const paysend = (params) => {

//     return request({
//         method: "POST",
//         data: params,
//         // url: "/tripartite/mobile/pay/paysend",
//         //  url: "/tripartite/mobile/pay/getpaycode",
//         url: "/tripartite/mobile/pay/code_pay",
//     });
// };
// //支付回调
// export const paylist = (params) => {

//     return request({
//         method: "POST",
//         data: params,
//         url: "/tripartite/mobile/pay/pay_status",
//     });
// };
// // 正式环境：mobile
// // 手机版：mobile
// 支付状态查询
// export const getamount = (params) => {

//   return request({
//       method: "POST",
//       data: params,
//       url: "tripartite/mobile/pay/get_amount",
//   });
// };

// export const getamount = (params) => {

//     return request({
//         method: "POST",
//         data: params,
//         url: "/QBaoHan/get_amount",
//     });
// };
// export const notifyurl = (params) => {

//     return request({
//         method: "POST",
//         data: params,
//         url: "/tripartite/mobile/pay/notify_url",
//     });
// };
// //pc网关支付
// export const pcpay = (params) => {

//     return request({
//         method: "POST",
//         data: params,
//         url: "/tripartite/mobile/pay/pc_pay",
//     });
// };
// // 支付信息提交
// export const bhpay = (params) => {

//     return request({
//         method: "POST",
//         data: params,
//         url: "/handle/Baohan/bhpay",
//     });
// };
// // 下载文件
// export const snfiledown= (dat) => {
//     return request({
//       method: "POST",
//       url: "tripartite/mobile/baohan/snfiledown",
//       data: {
//        ...dat
//       },
//     });
//   };

//   // 个人认证
// export const renzhenggeren = (params) => {

//     return request({
//         method: "POST",
//         data: params,
//         url: "/handle/index/renzheng_geren",
//     });
// };
//   // 个人身份证识别
//   export const idcard = (params) => {

//     return request({
//         method: "POST",
//         data: params,
//         url: "/handle/index/idcard",
//     });
// };
//   // 个人认证
//   export const rzchaxun = (params) => {

//     return request({
//         method: "POST",
//         data: params,
//         url: "/handle/index/rzchaxun",
//     });
// };
// // 获取对公识别码
// export const msgcode = (params) => {

//     return request({
//         method: "POST",
//         data: params,
//         url: "/tripartite/mobile/xbhan/msgcode",
//     });
// };
