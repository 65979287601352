<template>
  <div class="home xindes">
    <toubu></toubu>
    <!-- <hear></hear> -->
    <BDhear :num="5"></BDhear>

    <el-dialog
      title="基本户支付"
      :visible.sync="centerDialogVisible"
      width="60%"
      @close="centerguan"
    >
      <div class="xinwen">
        1、请使用交易系统中登记的公司基本账户>转账，非基本户转账将无法进行支付
      </div>
      <div class="xinwen hongs">2、无需填写备注(附言或用途)</div>
      <div class="xinwen">3、请一次全额转账支付，不能分多次转账!</div>
      <div class="xinwen">
        4、<span class="hongs">转账支付到账有延迟</span>
        ，为了您的投标顺利，请不要在临近保证金缴纳截止时间(1小时)进行担保费支付，否则可能存在到账时间超期，无法生成促涵风险，如需支付，<span
          class="hongs"
          >相关后果自负</span
        >
      </div>
      <div class="xinwen">
        5、<span class="hongs">转账支付到账有延迟</span
        >，请在转账完成后30分钟左右，回到保函平台查询支付状态
      </div>
      <div class="xinwen">
        <el-row class="wodeshi xawodeshi" :gutter="19">
          <el-col :span="17" :offset="4">
            <span class="hongs">对公识别码</span>
            <h1 class="shibei">{{ duigong }}</h1>
            <br />
            <span class="hongs"
              >注：请在对公打款备注增加对公识别码，系统实现自动快速出函，不能超过20个字符。</span
            >
          </el-col>
          <span @click="fuzhi(duigong)"
            ><el-button style="" class="textbutts makabd linheng" type="primary"
              >复制</el-button
            ></span
          >
        </el-row>
      </div>
      <div class="textwo">
        <div class="hongs textops zi jiacu">
          请务必注意打款账号，每笔申请账号都会有变化!
        </div>
        <div class="hongs textop zi jiacu">
          请使用手机银行、网银、或前往银行柜面向下面账户中转账!
        </div>
        <div class="textop">
          <el-row class="man">
            <el-col :span="9"><div class="textsan">公司名称：</div></el-col>
            <el-col :span="15">
              <div class="textqi jiacu">
                {{ bankinfo.orgName }}
              </div></el-col
            >
          </el-row>
        </div>
        <div class="textop">
          <el-row class="man">
            <el-col :span="9">
              <div class="textsan">组织机构代码：</div></el-col
            >
            <el-col :span="15">
              <div class="textqi">{{ bankinfo.unifyCode }}</div></el-col
            >
          </el-row>
        </div>
        <div class="textop">
          <el-row class="textsans man">
            <el-col :span="9"> <div class="textsan">开户银行：</div></el-col>
            <el-col :span="15">
              <div class="textqi jiacu">
                <span class="textqis">{{ bankinfo.bankname }}</span>
                <span class="textbutt"
                  ><el-button
                    @click="fuzhi(bankinfo.bankname)"
                    class="textbutts linheng"
                    type="primary"
                    >复制</el-button
                  ></span
                >
              </div></el-col
            >
          </el-row>
        </div>
        <!-- <div class="textop">
          <div class="textsan">开户银行：</div>
          <div class="textqi jiacu">郑州银行股份有限公司晨旭路支行</div>
        </div> -->
        <div class="textop">
          <el-row class="textsans man">
            <el-col :span="9">
              <div class="textsan">收款账户户名：</div></el-col
            >
            <el-col :span="15">
              <div class="textqi jiacu">
                <span class="textqis">{{ bankinfo.bankaccount }}</span>
                <span class="textbutt"
                  ><el-button
                    @click="fuzhi(bankinfo.bankaccount)"
                    class="textbutts linheng"
                    type="primary"
                    >复制</el-button
                  ></span
                >
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="textop">
          <el-row class="textsans man">
            <el-col :span="9"> <div class="textsan">开户账号：</div></el-col>
            <el-col :span="15">
              <div class="textqi jiacu">
                <span class="textqis">{{ bankinfo.bankcode }}</span>
                <span class="textbutt"
                  ><el-button
                    @click="fuzhi(bankinfo.bankcode)"
                    class="textbutts linheng"
                    type="primary"
                    >复制</el-button
                  ></span
                >
              </div>
            </el-col>
          </el-row>
        </div>
        <!--  -->
        <div class="textop">
          <el-row class="man">
            <el-col :span="9"> <div class="textsan">支付金额：</div></el-col>
            <el-col :span="15">
              <div class="textqi jiacu">
                {{ zhi }} <span class="heise">元 (含手续费0元)</span>
              </div></el-col
            >
          </el-row>
        </div>
        <!-- <div class="textop">
          <div class="textsan">开户账号：</div>
          <div class="textqi jiacu">
            <span class="textqis">999156009960020046</span>
            <span class="textbutt"
              ><el-button
                @click="fuzhi('999156009960020046')"
                class="textbutts"
                type="primary"
                >复制</el-button
              ></span
            >
          </div>
        </div> -->
        <div class="textop">
          <el-row class="textsans man">
            <el-col :span="9"> <div class="textsan">银行联号：</div></el-col>
            <el-col :span="15">
              <div class="textqi jiacu">
                <span class="textqis">{{ bankinfo.bankno }}</span>
                <span class="textbutt"
                  ><el-button
                    @click="fuzhi(bankinfo.bankno)"
                    class="textbutts linheng"
                    type="primary"
                    >复制</el-button
                  ></span
                >
              </div>
            </el-col>
          </el-row>

          <!-- <div class="textqi">313491000273</div> -->
        </div>
        <div class="textop">
          <el-row class="man">
            <el-col :span="9"> <div class="textsan">地址：</div></el-col>
            <el-col :span="15">
              <div class="textqi">
                {{ bankinfo.bankaddress }}
              </div></el-col
            >
          </el-row>
        </div>
        <div class="textop">
          <el-row class="man">
            <el-col :span="9"> <div class="textsan">电话：</div></el-col>
            <el-col :span="15">
              <div class="textqi">{{ bankinfo.telphone }}</div></el-col
            >
          </el-row>
        </div>
      </div>

      <!-- <span slot="footer" class="dialog-footer">
    <el-button @click="centerDialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="centerDialogVisible = false">确 定</el-button>
  </span> -->
    </el-dialog>
    <!-- <el-button type="text" @click="">点击打开 Dialog</el-button> -->
    <div>
      <el-main class="zong">
        <el-card class="tiops box-card">
          <div slot="header" class="clearfix">
            <div class="ieleft">待支付订单</div>
            <div class="ieright">
              <!-- <span>应付：</span> -->
              <span class="money">{{ zhityoe }}</span>
            </div>
          </div>
          <el-table empty-text class="qingchu" :data="tableData">
            <el-table-column prop="date" label="订单号" width="280">
            </el-table-column>
            <el-table-column prop="name" label="保函类型" width="180">
            </el-table-column>
            <el-table-column prop="address" label="产品"> </el-table-column>
            <!-- <el-table-column prop="金额" label="支付金额"> </el-table-column> -->
          </el-table>
        </el-card>
        <el-card
          v-if="zhifuanniu !== '下一步'"
          class="box-card"
          style="margin-top: 15px;"
        >
          <div slot="header" class="clearfix">
            <div class="ieleft">付款金额</div>
            <!-- <div
              style="
                font-size: 20px;
                font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                font-weight: 800;
                color: #1795ff;
              "
              v-else
            >
            v-if="!type"
              {{ type }}
            </div> -->
            <div class="ieright">
              <span>应付：</span>
              <span class="money">￥{{ zhi }}</span>
            </div>
          </div>

          <div class="qujian xiang zi">
            <div class="jiajs zi jue zhijue1 hang">
              <div @click="diajiben" class="jiben hang xinag pzi zi">
                <img
                  class="asimg jue zhijue4"
                  src="@/assets/zhi@2x.png"
                  alt=""
                  srcset=""
                />
                <span class="jue zhijue5">点击对公转账</span>
              </div>
            </div>
            <div class="xixian zhijue2 jue"></div>
            <div v-if="xuanzhifu" class="jianju hang zhijue3 jue">
              <div @click="jibenxuan(2)" class="jiben hang xinag pzi zi">
                <img
                  class="asimg jue zhijue4"
                  src="@/assets/zhi@2x.png"
                  alt=""
                  srcset=""
                />
                <span class="jue zhijue5">点击扫码支付</span>
              </div>
            </div>
            <div
              v-else
              class="jianju hang zhijue3 jue"
              style="display: flex; flex: 1;"
            >
              <div class="hang hangziimg" style="">
                <img
                  v-if="btnDisabled"
                  class="imgasd"
                  style=""
                  :src="url"
                  alt=""
                  srcset=""
                  @click="zhifu"
                />
                <img
                  v-else
                  @click="zhifu"
                  style="height: 200px;"
                  src="@/assets/BidSecurity/guoqi.png"
                  alt=""
                  srcset=""
                />
                <br />
                <!-- <span class="ziimg" v-if="btnDisabled"> 支付方式：支付宝/微信/云闪付</span> -->
                <span class="ziimg" v-if="btnDisabled">
                  支付方式：支付宝/微信/云闪付</span
                >
                <!-- @click="cionurl" -->
              </div>
              <div class="xutxt" style="">
                请勿重复支付
                <div style="color: red; font-size: 14px;">
                  （在线支付最快10s出函）
                </div>
                <!-- <div>
  <img class="asimg" src="@/assets/weix.png" alt="" srcset="">微信
</div>
<div>
  <img class="asimg" src="@/assets/zhi.png" alt="" srcset="">支付宝
</div>
<div>
  <img class="asimg" src="@/assets/shan.png" alt="" srcset="">云闪付
</div> -->
              </div>
            </div>
          </div>
          <div v-if="xinzhifuanniu !== '下一步'" class="remind zi">
            <span v-if="!xuanzhifu"> 扫码支付快速出函中（{{ daoji }}s）</span>
          </div>
          <div v-else class="remind zi">支付完成，请点击下一步</div>
        </el-card>
      </el-main>
    </div>
    <!-- <div v-if="zhifuanniu!=='下一步' && zhixian" style="    box-sizing: border-box;" class="bottom">
      <span  style="font-size: 14px">实付金额：</span>
      <span class="money asdas">￥{{ zhi }}</span>
      <el-button @click="zhibutt" type="warning">{{zhifuanniu}}</el-button>
    </div> -->
    <div
      v-if="zhifuanniu == '下一步'"
      style="justify-content: center; box-sizing: border-box;"
      class="bottom"
    >
      <el-button
        @click="zhibutt"
        style="width: 142px; background: #1795ff; border-color: #1795ff;"
        type="warning"
        >{{ zhifuanniu }}</el-button
      >
    </div>
    <div
      v-else
      style="justify-content: center; box-sizing: border-box;"
      class="bottom zi"
    >
      <el-button @click="chengongs" style="" type="warning" class="chaxun">{{
        xinzhifuanniu
      }}</el-button>
    </div>
    <!-- <el-dialog
      :title="cion"
      :visible.sync="dialogVisible"
      width="30%"
      @close="handleClosePay"
    >
      <div class="content">
        <h3></h3>
        <img
          class="pic"
          :style="{ opacity: btnDisabled ? 1 : 0.3 }"
          :src="url"
        />
        <el-button class="casdas" @click="chengong">确定支付</el-button>
      </div>
    </el-dialog> -->
    <!-- <el-dialog
      title="选择支付方式"
      :visible.sync="xuanzhifu"
      width="30%"
      @close="xuanzhifuguan"
    >
      <div class="content">
       
   <div @click="jibenxuan(1)">
    银行对公转账
   </div>
   <div @click="jibenxuan(2)">
    手机扫码支付
   </div>
      </div>
    </el-dialog> -->
    <!-- <el-dialog
      class="dialog-pay"
      title=""
      :visible.sync="dialogVisible"
      :show-close="false"
      @close="handleClosePay"
    >
      <div class="content">
        <p class="tip">{{  }}</p>
        <p class="tip">
          支付金额：<span class="small">￥</span
          ><span class="large">{{ zhi }}</span>
        </p>
        <img
          class="pic"
          :style="{ opacity: btnDisabled ? 1 : 0.3 }"
          :src="url"
        />
        <el-button
          class="btn"
          :class="btnDisabled ? 'disabled' : ''"
          type="primary"
         
          @click="handleRefreshCode"
          >{{ btnText }}</el-button
        >
      </div>
    </el-dialog> -->

    <div style="width: 100%;">
      <dinad></dinad>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import BDhear from '../../components/BDhear.vue'
import dinad from '../../components/dinad.vue'
import toubu from '../../components/toubu.vue'
import xianxia from './xindex.vue'
// import hear from ".././../components/hear.vue";
import { paysend, paylist, getamount, notifyurl, pcpay } from '@/api/xin.js'
import { bhpay, msgcode } from '@/api/houer'
export default {
  components: {
    BDhear,
    xianxia,
    dinad,
    toubu
  },
  name: 'WechatPay',
  props: {
    pay: Object
  },
  data() {
    return {
      daoji: 10,
      dialogVisible: true,
      zhifuanniu: '点击支付',
      zhityoe: '未支付',
      activeName: 'first',
      xinzhifuanniu: '查询到账',
      radio: '1',
      url: '',
      zhi: '',
      type: '',
      // zhi: 1,
      cion: '',
      bankinfo: {},
      merOrderId: '',
      tableData: [
        {
          date: sessionStorage.getItem('baohanno'),
          name: '',
          address: '',
          jine: ''
        }
      ],
      zhifutype: 1,
      duigong: null,
      centerDialogVisible: false,
      xuanzhifu: true,
      xuazne: true,
      sparams: {
        page: 0,
        page_limit: 10,
        state: 2,
        merOrderId: '',
        baohanno: sessionStorage.getItem('baohanno')
        // user_id: sessionStorage.getItem("id"),
      },
      dialogVisibles: true,
      btnDisabled: true,
      btnText: '',
      // second: 60,
      timer: null,
      refresh: true,
      tijiao: 0,
      tijiaos: 0,
      dialogVisible: false,
      btnDisabled: true,
      btnText: '',
      second: 400, //倒计时
      timer: null,
      refresh: true,
      zhixian: true,
      xindeyinc: true
    }
  },
  // watch: {
  //   "pay.show": {
  //     handler(val) {
  //       if (val) {
  //         this.dialogVisible = this.pay.show;
  //         setTimeout(this.handleReportPayNotify(), 5000);
  //       }
  //     },
  //     immediate: true,
  //   },
  // },
  created() {
    getamount({
      baohanno: sessionStorage.getItem('baohanno')
    })
      .then((res) => {
        // this.$notify.error({
        //   message: res.data.msg,
        // });
        console.log('支付信息', res.data)
        if (res.data.code == 200) {
          this.zhi = res.data.data.amount
          // this.zhi=res.data.data
          this.tableData[0].name = res.data.data.gtype
          this.tableData[0].address = res.data.data.cp_name
          this.tijiaos = 'zb'
          this.type = '支付宝'

          // return //测试
          if (res.data.data.status == 2) {
            this.zhifuanniu = '下一步'
            this.zhityoe = '已支付'
          }
          //  this.$message({
          //           showClose: true,
          //           message: res.data.msg,
          //           type: 'success'
          //         });
        } else {
          this.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
      .catch((err) => {
        console.log('失败', err)
      })
  },
  mounted() {},
  methods: {
    jibenxuan(e) {
      if (e == 1) {
      } else {
        this.xuanzhifu = false
        this.zhifu()
      }
    },
    handleClick(tab, event) {
      tab.index = tab.index
      this.tijiao = tab.index
      clearInterval(this.downTimer)
      this.second = 400 //倒计时
      //  console.log(tab.index);
      if (tab.index == 2) {
        this.centerDialogVisible = true
      }
      if (tab.index == 3) {
        this.zhixian = false
      } else {
        this.zhixian = true
      }
      this.zhibutt()
    },
    // 刷新二维码
    handleRefreshCode() {
      this.zhifu()
      //   this.$bus.$emit('refreshCode')
      this.handleCountDown()
      // this.handleReportPayNotify();
      this.refresh = false
      this.btnDisabled = false
    },
    zhifu() {
      console.log('easasdzhifu')
      clearInterval(this.remindTimer)
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        // spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      //  console.log("调用支付");
      if (this.zhifuanniu == '下一步') {
        this.$message({
          showClose: true,
          message: '已支付',
          type: 'success'
        })
        return
      }
      var bicin = {
        // pay_type: 1,
        // // user_id:sessionStorage.getItem("user_id"),
        // amount: 1 + "",
        baohanno: sessionStorage.getItem('baohanno')
      }
      paysend(bicin).then((res) => {
        console.log('tijiao', res.data.data)
        // return
        loading.close()

        if (res.data.code == 200) {
          this.remindtime()
          // this.dialogVisible = true;
          this.zhifuanniu = '确定支付'
          if (res.data.data.url) {
            this.url = 'data:image/png;base64,' + res.data.data.baseCode
          }

          // return //测试
          this.handleCountDown()
          this.sparams.merOrderId = res.data.data.merOrderId
          // console.log(this.sparams.merOrderId);
          this.btnDisabled = true
          // this.url = "https://www.hnzyxsbh.com/" + res.data.data.url;
        } else {
          //  console.log("zhifu失败", res);
          this.btnDisabled = false
          this.$notify.error({
            message: res.data.msg
          })
        }
      })
    },
    chengong() {
      // this.sparams.merOrderId="336J20221109111604139971"
      paylist(this.sparams).then((res) => {
        // console.log("发票列表", res);
        if (res.data.code == 1) {
          console.log(
            'res.data.data.rows[0].state',
            res.data.data.rows[0].state
          )
          return
          if (res.data.data.rows[0].state == 2) {
            this.$router.push('/added')
          } else {
            ;(this.type = ''), (this.zhifuanniu = '点击支付')
            this.$message({
              showClose: true,
              message: '支付未成功或没有支付，请重新进行支付',
              type: 'warning'
            })
          }
        } else {
          console.log('失败', err.response.data)
          this.$notify.error({
            message: err.response.data.msg
          })
        }
      })
      // this.$router.push("/public");
      return
      notifyurl({
        amount: this.zhi * 100 + '',
        guarantee_id: sessionStorage.getItem('guarantee_apply_id')
      }).then((res) => {
        console.log('tijiao', res)
        if (res.data.code == 1) {
        } else {
          console.log('失败', err.response.data)
          this.$notify.error({
            message: err.response.data.msg
          })
        }
      })
    },
    bhpays() {
      var bbc = {}
      ;(bbc.baohanno = sessionStorage.getItem('baohanno')),
        (bbc.user_id = 3),
        (bbc.image = '1231312')
      bbc.bankname = '1231312'
      bbc.bankcode = '1231312'
      bbc.bankaccount = '1231312'
      bbc.rebankname = '1231312'
      bbc.rebankcode = '1231312'
      bbc.rebankaccount = '1231312'
      // sessionStorage.setItem("zhi",this.ruleForm.rebankaccount)
      // this.$router.push("/Bidpayments");

      //    return

      bhpay(bbc).then((err) => {
        //    console.log("已经提交", err.data.data);
        // this.ruleForm=err.data.data.info
        if (err.data.code == 1) {
          this.$message({
            message: '提交成功请等待审核',
            type: 'success'
          })
          this.$router.push('/Public')
        } else {
          this.$message({
            message: err.data.mag,
            type: 'success'
          })
          // this.$message.error(err.data.mag);
        }
      })
    },
    zhibutt() {
      if (this.zhifuanniu == '下一步') {
        return this.$router.push('/added')
      }
      // console.log(123, this.tijiao);
      //   this.$router.push("/added");
      // return
      // if (this.zhifuanniu == "确定支付") {
      //   this.chengong();
      //   return;
      // }
      // this.bhpays()
      // return
      if (this.tijiao == 0) {
        this.tijiaos = 'zb'
        this.type = '支付宝'
        this.zhifu()
        // &&this.tijiao == "wx"
      }
      if (this.tijiao == 1) {
        this.tijiaos = 'wx'
        this.type = '微信'
        this.zhifu()
      }
      if (this.tijiao == 2) {
        // this.zhifus();
        this.type = '线下支付'
      } else {
        // this.zhifu();
      }
    },
    zhibutts() {
      if (this.zhifuanniu == '下一步') {
        return this.$router.push('/added')
      }
    },
    zhifub1() {
      clearInterval(this.downTimer)
      console.log('zhifbao')
      this.tijiaos = 'zb'
      this.type = '支付宝'
      this.zhifu()

      this.second = 400 //倒计时
    },
    weixz() {
      clearInterval(this.downTimer)
      this.tijiaos = 'wx'
      this.type = '微信'
      // this.zhifu();

      this.second = 400 //倒计时
    },
    wangyz() {
      clearInterval(this.downTimer)
      this.zhifus()

      this.second = 400 //倒计时
    },
    zhifus() {
      pcpay({
        // amount: this.zhi * 100 + "",
        baohanno: sessionStorage.getItem('baohanno')
      }).then((res) => {
        //    this.$notify.error({
        //   message: res.data.msg
        // });

        console.log('tijiao', res, res.data.code == 1)
        if (res.data.code == 1) {
          //  console.log("zgif", res.data.data.data);
          window.location.href = res.data.data.data
          // this.dialogVisible=true
          // this.url="https://www.hnzyxsbh.com/"+res.data.data.url
        } else {
          console.log('失败', res)
        }
      })
    },
    // 二维码倒计时
    handleCountDown() {
      this.downTimer = setTimeout(() => {
        // this.chengongs(); //测试
        this.zhifu()
        // this.handleCountDown(900000);
      }, 900000)
      //  clearInterval(this.timer);
      return
      if (this.second == 1) {
        if (this.refresh) {
          this.second = 400 //倒计时
          this.btnDisabled = false
          this.btnText = '刷新中'
          setTimeout(() => {
            this.zhifu()
          }, 2000)

          if (this.timer) {
            clearInterval(this.timer)
          }
        } else {
          this.$emit('closePay', { type: 'fail' })
          clearInterval(this.timer)
          this.$message.warning('等待时间过长，请重新发起支付')
        }
      } else {
        this.second--
        this.btnDisabled = true
        this.btnText = `剩余${this.second}秒`
        this.downTimer = setTimeout(() => {
          this.chengongs() //测试
          this.handleCountDown()
        }, 1000)
      }
    },
    remindtime() {
      //   this.downTimer = setTimeout(() => {
      //     // this.chengongs(); //测试
      //     this.zhifu()
      //     // this.handleCountDown(900000);
      //   }, 900000);
      // //  clearInterval(this.timer);
      // return;
      if (this.daoji == 0) {
        this.daoji = 11 //倒计时
        this.remindtime()
      } else {
        this.daoji--
        this.remindTimer = setTimeout(() => {
          this.remindchen()
          this.remindtime()
        }, 1000)
      }
    },
    chengongs() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        // spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      // this.sparams.merOrderId="336J20221109111604139971"
      paylist(this.sparams).then((res) => {
        console.log('发票列表', this.second, res)
        loading.close()
        if (res.data.code == 200) {
          // if (this.second == 30) {
          // }
          // res.data.data=2 //书豪测试
          if (res.data.data == 2) {
            clearInterval(this.downTimer)
            // this.zhifuanniu = "下一步";
            // this.zhityoe = "已支付";
            // this.xindeyinc = false;
            // this.btnText = "支付成功";
            this.$router.push('/added')
          } else {
            ;(this.type = ''), (this.zhifuanniu = '点击支付')
            //   this.$notify.error({
            //   message: '等待支付',
            // });
            this.$message({
              showClose: true,
              message: '支付未成功或没有支付',
              type: 'warning'
            })
          }
        } else {
          clearInterval(this.downTimer)
          // console.log("失败", res.response.data);
          this.$notify.error({
            message: '等待支付'
          })
        }
      })
      // this.$router.push("/public");
      return
    },
    remindchen() {
      // this.sparams.merOrderId="336J20221109111604139971"
      paylist(this.sparams).then((res) => {
        //   console.log("发票列表", this.second, res);

        if (res.data.code == 200) {
          // if (this.second == 30) {
          // }
          if (res.data.data == 2) {
            clearInterval(this.remindTimer)
            this.xinzhifuanniu = '下一步'
            this.$message({
              showClose: true,
              message: '支付成功',
              type: 'success'
            })
            this.zhityoe = '已支付'
            // this.xindeyinc = false;
            this.btnText = '支付成功'
            //  this.$router.push("/added");
          } else {
            ;(this.type = ''), (this.zhifuanniu = '点击支付')
            //         this.$message({
            //   showClose: true,
            //   message: '支付未成功或没有支付，请重新进行支付',
            //   type: 'warning'
            // });
          }
        } else {
          clearInterval(this.downTimer)
          console.log('失败', res.response.data)
          this.$notify.error({
            message: res.response.data.msg
          })
        }
      })
      // this.$router.push("/public");
      return
    },
    // handleClosePay() {
    //         this.$message.warning("您已取消支付");
    // },
    xuanzhifuguan() {
      this.$message.warning('您已取消支付')
    },
    centerguan() {
      clearInterval(this.remindTimer)
    },
    // 监听支付弹窗关闭
    handleClosePay() {
      if (this.timer) {
        clearInterval(this.timer)
      }
      if (this.downTimer) {
        clearTimeout(this.downTimer)
      }
      this.$emit('closePay', { type: 'fail' })
      this.$message.warning('您已取消支付')
    },
    // 监测支付回调结果
    handleReportPayNotify() {
      let num = 0
      this.timer = setInterval(() => {
        num++
        this.pay.fn().then((res) => {
          if (res.status == 111111) {
            this.$emit('closePay', { type: 'success' })
            clearInterval(this.timer)
          }
        })
        if (num == 12) {
          this.$emit('closePay', { type: 'fail' })
          clearInterval(this.timer)
          // this.$message.warning("等待时间过长，请重新发起支付");
        }
      }, 5000)
    },
    fuzhi(data) {
      //点击某个摁扭执行事件

      let url = data
      //新建一个文本框
      let oInput = document.createElement('input')
      //赋值给文本框
      oInput.value = url
      document.body.appendChild(oInput)
      // 选择对象;
      oInput.select()
      // 执行浏览器复制命令
      document.execCommand('Copy')
      //复制完成删除掉输入框
      if (
        !!window.ActiveXobject ||
        'ActiveXObject' in window ||
        /Trident\/7\./.test(navigator.userAgent)
      ) {
        oInput.removeNode(true)
      } else {
        oInput.remove()
      }

      //判断是否IE浏览器

      // oInput.remove();
      this.$message({
        showClose: true,
        message: '复制成功',
        type: 'success'
      })
      console.log('复制成功')
    },

    beforeDestroy() {
      if (this.timer) {
        clearInterval(this.timer)
      }
      if (this.downTimer) {
        clearTimeout(this.downTimer)
      }
    },
    cionurl() {
      //   console.log(this.btnDisabled);
      if (this.btnDisabled == false) {
        ;(this.btnDisabled = true), this.handleRefreshCode()
        // this.zhifu()
        // this.handleCountDown()
      }
    },
    diajiben() {
      this.zhifutype = 2
      clearInterval(this.remindTimer)
      this.xuanzhifu = true
      console.log('Check')
      var copm = {
        applyno: sessionStorage.getItem('applyno'),
        unify_code: sessionStorage.getItem('unify_code')
        // unify_code: sessionStorage.setItem("unify_code"),
      }
      msgcode(copm).then((err) => {
        if (err.data.code == 200) {
          console.log(err.data)
          this.duigong = err.data.data.code
          this.bankinfo = err.data.data.bankinfo
          this.centerDialogVisible = true
          // this.remindtime()  // 轮询接口查询支付状态
        } else {
          this.$message({
            message: err.data.mag,
            type: 'success'
          })
          // this.$message.error(err.data.mag);
        }
      })
    },
    cionurls() {
      console.log('diaji')
      if (this.btnText == '点击刷新') {
        console.log('点击刷新')
        //    console.log(this.btnDisabled);
        if (this.btnDisabled == false) {
          ;(this.btnDisabled = true), this.handleRefreshCode()
          // this.zhifu()
          // this.handleCountDown()
        }
      }
    }
  }
}
</script>
<style>
.heise {
  margin-left: 5px;
  color: #606266;
  font-size: 14px;
}
.heises {
  font-size: 16px;
}
.xawodeshi {
  margin-left: 0px !important;
  width: 100% !important;
}
.zong .el-card__body {
  padding-top: 0px !important;
}
.xindes .el-dialog__body {
  padding: 1.7rem 1.25rem;
  padding-top: 1rem;
  color: #606266;
  font-size: 0.875rem;
  word-break: break-all;
}

.xindes .el-dialog {
  margin-top: 10vh !important;
}
.xindes .el-main {
  overflow: hidden;
  /* margin-bottom: 10px; */
}
.xindes .el-dialog__header {
  padding: 5px 10px;
  background: #f3f3f3;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.xindes .el-dialog__headerbtn {
  position: static;
}
.xindes .el-dialog__title {
  line-height: 1.5rem;
  font-size: 0.9rem;
  color: #303133;
}
.imgasd {
  height: 205px;
  width: 200px;
  padding: 1px;
  margin-top: -5px;
  border: 3px #f1f0f5 solid;
  cursor: pointer;
}
.xutxt {
  /* width: 100%; */
  display: flex;
  align-items: flex-end;
  padding-left: 10px;
}
.xindes .el-dialog__headerbtn .el-dialog__close {
  color: #000;
  font-weight: 900;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
html body {
  background: #eeeeee;
  height: 100% !important;
}
</style>

<style scoped>
.makabd {
  float: right;
  margin-top: 1%;
  margin-right: 18px;
}
.wodeshi {
  border: 1px solid;
  padding: 15px;
}
.shibei {
  border: 1px solid;
  padding: 10px 20px;
  float: right;
  margin-left: 20px;
  color: #000;
  letter-spacing: 5px;
}
.man {
  width: 100%;
}
tixing {
  color: red !important;
  font-size: 14px !important;
}
.xinwen {
  line-height: 26px;
}
.textwo {
  border: 1px solid;
  line-height: 2rem;
  margin-top: 15px;
}
.jiacu {
  color: red;
  font-weight: 600;
}
.textop {
  border-top: 1px #000 solid;
  display: flex;
  justify-content: center;
  align-items: center;
}
.textbutt {
  flex: 3;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
}
.textbutts {
  height: 15px;
  /* line-height:15px; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
}
.textops {
  display: flex;
  justify-content: center;
  align-items: center;
}
.textsan {
  flex: 3;
  height: 100%;
  text-align: right;
  background: #c9e4f3;
}
.textsans {
  background: #c9e4f3;
}
.textqi {
  flex: 7;
  background: #f1f1f1;
  padding-left: 10px;
  display: flex;
  /* justify-content:center; */
  align-items: center;
}
.textqis {
  flex: 7;

  display: flex;
  /* justify-content:center; */
  align-items: center;
}
.hongs {
  color: rgb(255, 0, 0);
}
.sk-circle {
  /* margin: 10px auto; */
  width: 140px;
  height: 140px;
  position: relative;
}
.sk-circle .sk-child {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.sk-circle .sk-child:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 10%;
  height: 10%;
  background-color: #298cde;
  border-radius: 100%;
  -webkit-animation: sk-circleBounceDelay 2s infinite ease-in-out both;
  animation: sk-circleBounceDelay 2s infinite ease-in-out both;
}
.sk-circle .sk-circle2 {
  -webkit-transform: rotate(40deg);
  -ms-transform: rotate(40deg);
  transform: rotate(40deg);
}
.sk-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg);
}
.sk-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.sk-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg);
}
.sk-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg);
}
.sk-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.sk-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
  -ms-transform: rotate(210deg);
  transform: rotate(210deg);
}
.sk-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  transform: rotate(240deg);
}
.sk-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}
.sk-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
  -ms-transform: rotate(300deg);
  transform: rotate(300deg);
}
.sk-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  transform: rotate(330deg);
}
.sk-circle .sk-circle2:before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
.sk-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
.sk-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
.sk-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}
.sk-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}
.sk-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}
.sk-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}
.sk-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}
.sk-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}
.sk-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}
.sk-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleBounceDelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.zi {
  text-align: center;
}
@keyframes sk-circleBounceDelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
html body {
  background: #eeeeee;
  height: 100% !important;
}
.app {
  height: 100%;
}
.home {
  height: 100%;
  width: 100%;
  /* display: flex; */
  box-sizing: border-box;
  flex-direction: column;
  position: relative;
  background: #f0eff4;
  /* padding-bottom: 20px; */
}
.el-tabs__content {
  overflow: inherit !important;
  /* position: relative; */
}
.div {
  /* height: 65%; */
  display: flex;
  flex-direction: column;
  /* align-items: flex-end; */
}
.cions-img {
  width: 200px;
  padding: 0 20px;
}
.casdas {
  width: 80%;
  margin-top: 20px;
  margin-bottom: 20px;
}
.tiops {
  /* margin-top: 20px; */
}
.el-card__body,
.el-main {
  padding: 20px;
  padding-top: 0 !important;
  padding-bottom: 40px;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.pic {
  width: 80%;
}
.el-main {
  flex: 1;
  box-sizing: border-box;
  padding: 20px;
  background-color: #f1f0f5;
  padding-bottom: 40px;
}
.asdas {
  margin-right: 10px;
}
.el-footer {
  height: 100px !important;
  width: 100%;
  /* background-color: #333; */
}

.clearfix {
  width: 100%;
  height: 27px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.el-radio {
  width: 200px;
  height: 80px;
  line-height: 80px;
}
::v-deep.el-radio .el-radio__label {
  font-size: 18px !important;
}
.bottom {
  /* height: 100%; */
  /* position: absolute; 
 bottom: 80px; */
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: #ffff;
  justify-content: center;
  box-sizing: border-box;
}
.chaxun {
  width: 142px;
  background: #1795ff;
  border-color: #1795ff;
}
.money {
  color: #e8862a;
  font-size: 20px;
  text-align: center;
}
.el-button--small {
  width: 80px;
  margin-left: 16px;
}
.asimg {
  width: 2rem;
  height: 2rem;
  margin-right: 10px;
  cursor: pointer;
}
.jiben {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 0 0 0;
  opacity: 1;
  width: 190px;
  /* margin: 0 auto; */
  font-size: 18px;
  height: 190px;
  text-align: center;
  border: 0.0625rem solid #999999;
  padding: 0.625rem;
  cursor: pointer;
  /* margin-right: 10px; */
}
.jianju {
  display: flex;
  flex: 1;
  width: 50%;
  /* justify-content: center; */
}
.qingchu {
  width: 100%;
}
::v-deep .qingchu .el-table__body {
  width: 100% !important;
}
::v-deep .qingchu .el-table__header {
  width: 100% !important;
}
.jiajs {
  height: 100%;
  display: flex;
  flex: 1;
  width: 50%;
  justify-content: flex-end;
  align-items: center;
  /* margin-right: 10px; */
  /* border-right: 2px solid #999999; */
}
.qujian {
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  padding: 20px 0;
  height: 250px;
}
.remind {
  display: flex;
  /* width: 100%; */
  justify-content: center;
  align-items: center;
}
.xixian {
  border-left: 1px solid;
  width: 1px;
  height: 90%;
  margin: 0 10px;
}
.ziimg {
  margin-top: -20px;
  display: inherit;
  font-size: 10px;
}
.hangziimg {
  position: relative;
}
</style>
