<template>
  <div class="app">
    <div style="" v-if="tuibao">
      <div class="app-xt">
        <div class="app-b">
      <div style="display: flex;
    justify-content: space-around;">
            <div style="flex:1;margin-right: 20px;">
            <div class="c-txt">对公账户信息</div>
            <div style="      border: 0;
    box-sizing: border-box;" class="b-bbc">
              <div>
                <div class="text item">
                  <span class="item-life">账户名称：</span>
                  <span class="abc">{{ cion.bankaccount }}</span>
                </div>
                <div class="text item">
                  <span class="item-life">账户号码：</span>
                  <span class="abc">{{ cion.bankcode }}</span>
                </div>
                <div class="text item">
                  <span class="item-life">支付金额：</span>
                  <span class="abc">{{ cion.cost }}元</span>
                </div>
              </div>
              <div>
                <div class="text item">
                  <span class="item-life">开户银行：</span>

                  <span class="abc">{{ cion.bankname }}</span>
                </div>
                <div class="text item">
                  <span class="item-life">银行联行号：</span>

                  <span class="abc">{{ cion.bankno }}</span>
                </div>
              </div>
            </div>
            <div class="c-txt">当前进度</div>
             <div style="      border: 0;
    box-sizing: border-box;" class="b-bbc">
              <div>
                <div class="text item">
                  <!-- <span class="item-life">账户名称：</span> -->
                  <span style="color: #ff417b" class="abc">{{ indexs }}</span>
                </div>
               
              </div>
         
            </div>
            <!-- <div style="color: #ff417b">{{ indexs }}</div> -->
          </div>
          <div style="flex:1;">
            <div class="c-txt">打款人账户信息</div>
            <!-- </el-card> -->
            <div style="    
    box-sizing: border-box;    border: 0;" class="app-c inout-1">
              <!-- <div class="csdff">必须使用对公账户</div> -->
              <div class="znashi">
                <div class="input" style="justify-content: flex-start">
                  <!-- <div class="input-flex">
                  <div class="flex-txt1">
                    <span class="flex-txt">*</span>账户名称
                  </div>

                  <el-input
                    class="input-1"
                    placeholder="填写账号名称"
                    v-model="input.bankname"
                    style="color: #333333"
                  >
                  </el-input>
                </div> -->

                  <!-- <div class="input-flex">
                <div style=" width:50%;" class="flex-txt1">
                  <span class="flex-txt">*</span>账户号码
                </div>
                <el-input
                  class="input-1"
                  placeholder="填写账户号码"
                  v-model="input.bankaccount"
                
                >
                </el-input>
              </div> -->
                  <!-- 
            </div>
            <div>
            <div class="input-flex">
                <div class="flex-txt1">
                  <span class="flex-txt">*</span> 开户银行
                </div>
                <el-input
                  class="input-1"
                  placeholder="填写开户行"
                  v-model="input.bankcode"
              
                >
                </el-input>
              </div> -->

                  <!-- <div class="input-flex">
                <div class="flex-txt1 uppder">
                  <span class="flex-txt">*</span>上传支付凭证
                </div>
              </div> -->
                </div>
                <div class="input-flex">
                  <div style="font-size: 18px; text-align: left">
                    <span class="flex-txt">*</span>付款账户
                  </div>
                  <el-input
                    class="input-1s"
                    placeholder="填写付款账户"
                    v-model="input.bankname"
                  >
                  </el-input>
                </div>
                <div class="input-flex">
                  <div style="font-size: 18px; text-align: left">
                    <span class="flex-txt">*</span>付款账号
                  </div>
                  <el-input
                    class="input-1s"
                 
                    placeholder="填写付款账号"
                    v-model="input.bankaccount"
                  >
                     <!-- @blur="luhnCheck(input.bankaccount)" -->
                  </el-input>
                </div>

                <div class="input-flex">
                  <div class="flex-txt1 uppder">
                    <!-- <el-button type="text" @click="open">上传支付凭证</el-button> -->
                    <span class="flex-txt">*</span>上传支付凭证
                  </div>
                </div>
              </div>
              <div class="bacl">
                <div class="input-flex">
                  <el-upload
                    class="avatar-uploader"
                    action="https://www.hnzyxsbh.com/mobile/common/upload/"
                    :show-file-list="false"
                    :on-success="handleAvatarSuccess"
                    :before-upload="beforeAvatarUpload"
                  >
                    <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                </div>
              </div>
            </div>
          </div>
      </div>
          <div
            style="
              padding: 0.5rem 0.9375rem;
              font-size: 1.1rem;
              text-align: left;
              border: 0.0625rem solid #d9d9d9;
              margin-top: 10px;
              display: flex;
              justify-content: space-between;
            "
         v-if="false"
          >
             <!-- v-if="!bajsd" -->
            
          </div>
        </div>
      </div>
      <div style="background: #fff" class="butt">
        <div v-if="bajsd" @click="entryUserInfo" class="button-i">提交支付</div>
        <div v-else @click="tiahsd" class="button-i">{{ tijiao }}</div>
      </div>
    </div>
    <div v-else class="app-bbb">
      <div class="">
        <el-card class="box-card">
          <div class="text item">当前保单已退保</div>
        </el-card>
      </div>
    </div>
    <!-- <div class="asdzong">
  <div style=" padding: 38px; border-bottom: 3px #efefef solid;">

    <img src="../../assets/login/lianxiwomen.png" class="asdimgh"  alt="" srcset=""></div>
  <div style="margin-top: 20px; display:flex; flex-direction:column; align-items: center;"><div><img src="../../assets/login/tang.png" class="asdimgh" alt="" srcset=""></div>
  <div style="font-size: 14px;">业务联系人：唐先生</div>
   <div style="font-size: 14px;">联系电话：13140161000</div>
  </div>
   <div style="margin-top: 20px; display:flex; flex-direction:column; align-items: center;"><div><img src="../../assets/login/zhang.png" class="asdimgh" alt="" srcset=""></div>
  <div style="font-size: 14px;">技术联系人：张先生</div>
   <div style="font-size: 14px;">联系电话：13838271800</div>
  </div>
</div> -->
  </div>
</template>

<script>
import {
  bhtypelist,
  applyprogress,
  entryUserInfo,
  bhprocess,
} from "@/api/user";

export default {
  data() {
    return {
      input: {
        applyno: "",
        unify_code: "",
        zfimage: "",
        bankname: "",
        // bankcode: "123",
        bankaccount: "",
        // jbrname:"",
        // bankno: "",
      },
      quan: false,
      active: 0,
      bankaccount: "",
      tong: true,
      tongbu: "同步资料中",
      tabPosition: "right",
      tab: "left",
      dialogImageUrl: "",
      cion: "",
      dialogVisible: false,
      unify_code: "",
      imageUrl: "",
      applyno: "",
      tijiao: "提交支付",
      timer: null, // 定时器名称
      bajsd: true,
      state: "",
      tuibao: true,
      truesss: false,
      jbrphone: "",
      payer: "",
      indexs: "待支付",
      statemsg: "",
    };
  },
  created() {
    // this.tiao()

    var bbc = sessionStorage.getItem("unify_code");
    this.unify_code = bbc;
    this.input.unify_code = bbc;

    // this.input.baohanno = this.$route.params.bao;
    this.input.applyno = sessionStorage.getItem("applyno");
    this.applyno = sessionStorage.getItem("applyno");
    this.asjhdja(); //判断url是否存在
    this.bhtypelist(); //银行数据

    // this.tiao();
  },

  methods: {
    async bhtypelist() {
      var cion = this.input.applyno;
      var cin = this.unify_code;
      var bbc = {
        cion,
        cin,
      };

      const { data } = await bhtypelist(bbc);
      this.cion = data.data;
    },
    async asjhdja() {
      var bbc = this.applyno;
      const { data } = await bhprocess(bbc);
      console.log("panduanuirl",data)
      this.msg = data.msg;
      this.state = data.data.state;
      // "0":__('等待审核'),"1":__('等待支付'),"2":__('已经出函'),"3":__('已经还原'),"4":__('已经退保')
      if (this.state == 4) {
        this.tuibao = false;
        return;
      }
        if (this.state == 2) {
          console.log("跳转！！！！！")
  this.$router.push({
          name: "defray",
        });
        // this.tuibao = false;
        return;
      }
      var bimg = data.data.image;
      // if(this.state===0){
      //   this.bajsd=true
      // }
      if (data.data.image == null) {
        //url不存在不改变
        this.enstate();
        return;
      } else {
        // 存在则填充并调用下一步获取按钮状态
        this.imageUrl = bimg;
        // this.tijiao = "下一步";
        // this.bajsd = false;
        this.enstate();
      }
    },
    luhnCheck(bankno) {
      return true;
      // bankno = '6217002540003927842'
      // bankno = '6222600810010710887'
      // bankno = '6225881414207430'
      console.log(bankno);
      var lastNum = bankno.substr(bankno.length - 1, 1); //取出最后一位（与luhn进行比较）
      var first15Num = bankno.substr(0, bankno.length - 1); //前15或18位
      var newArr = new Array();
      for (var i = first15Num.length - 1; i > -1; i--) {
        //前15或18位倒序存进数组
        newArr.push(first15Num.substr(i, 1));
      }
      var arrJiShu = new Array(); //奇数位*2的积 <9
      var arrJiShu2 = new Array(); //奇数位*2的积 >9
      var arrOuShu = new Array(); //偶数位数组
      for (var j = 0; j < newArr.length; j++) {
        if ((j + 1) % 2 == 1) {
          //奇数位
          if (parseInt(newArr[j]) * 2 < 9)
            arrJiShu.push(parseInt(newArr[j]) * 2);
          else arrJiShu2.push(parseInt(newArr[j]) * 2);
        } //偶数位
        else arrOuShu.push(newArr[j]);
      }
      var jishu_child1 = new Array(); //奇数位*2 >9 的分割之后的数组个位数
      var jishu_child2 = new Array(); //奇数位*2 >9 的分割之后的数组十位数
      for (var h = 0; h < arrJiShu2.length; h++) {
        jishu_child1.push(parseInt(arrJiShu2[h]) % 10);
        jishu_child2.push(parseInt(arrJiShu2[h]) / 10);
      }
      var sumJiShu = 0; //奇数位*2 < 9 的数组之和
      var sumOuShu = 0; //偶数位数组之和
      var sumJiShuChild1 = 0; //奇数位*2 >9 的分割之后的数组个位数之和
      var sumJiShuChild2 = 0; //奇数位*2 >9 的分割之后的数组十位数之和
      var sumTotal = 0;
      for (var m = 0; m < arrJiShu.length; m++) {
        sumJiShu = sumJiShu + parseInt(arrJiShu[m]);
      }
      for (var n = 0; n < arrOuShu.length; n++) {
        sumOuShu = sumOuShu + parseInt(arrOuShu[n]);
      }
      for (var p = 0; p < jishu_child1.length; p++) {
        sumJiShuChild1 = sumJiShuChild1 + parseInt(jishu_child1[p]);
        sumJiShuChild2 = sumJiShuChild2 + parseInt(jishu_child2[p]);
      }
      //计算总和
      sumTotal =
        parseInt(sumJiShu) +
        parseInt(sumOuShu) +
        parseInt(sumJiShuChild1) +
        parseInt(sumJiShuChild2);
      //计算luhn值
      var k = parseInt(sumTotal) % 10 == 0 ? 10 : parseInt(sumTotal) % 10;
      var luhn = 10 - k;
      if (lastNum == luhn) {
        console.log("luhn验证通过");
        return true;
      } else {
        this.$message({
          showClose: true,
          message: "银行账号输入不合法",
          type: "warning",
        });
        // console.log("银行卡号必须符合luhn校验")
        return false;
      }
    },
    tiao() {
      var that = this;
      // console.log(123);
      if (this.unify_code == "") {
        that.$router.push({
          name: "project",
        });
      }
    },
    next(e) {
      // console.log(e);
      this.active++;
      // console.log(this.active);

      if (this.active++ >= 5) {
        (this.tong = false), (this.tongbu = "同步资料完成");
        this.active = 4;
      }
    },
    async tiahsd() {
      var bbc = this.applyno;
      const { data } = await bhprocess(bbc);
      if (data.data.state == 0) {
        this.$alert(data.data.statemsg, "提示", {
          confirmButtonText: "确定",
          customClass: "messageasd",
          callback: (action) => {
            this.enstate();
          },
        });
        // window.location.reload();
        true;
      } else {
        this.$router.push({
          name: "added",
          params: {},
        });
        //      this.$alert(this.statemsg, '提示', {
        //   confirmButtonText: '确定',
        //     customClass:"messageasd",
        //   callback: action => {

        //   }
        // });
      }
    },
    async entryUserInfo() {
      var bbc = {
        ...this.input,
      };

      // if(this.input.bankname==""){
      //    this.$notify({
      //   title: "提醒",
      //   message:"账号名称不能为空" ,
      // });
      // return
      // }else
      // if (this.input.bankaccount == "") {
      //   this.$notify({
      //     title: "提醒",
      //     message: "账户号码不能为空",
      //   });
      //   return;
      // } else if (this.bankcode == "") {
      //   this.$notify({
      //     title: "提醒",
      //     message: "开户银行不能为空",
      //   });
      //   return;
      // } else
      if (this.imageUrl == "") {
        this.$notify({
          title: "提醒",
          message: "请上传图片",
        });
        return;
      } else {
        //    this.$notify({
        //   title: "提醒",
        //   message: data.msg,
        // });
      }
      const { data } = await entryUserInfo(bbc);
      if (data.msg == "支付提交成功") {
        //  console.log(data);
        this.quan = true;
        this.enstate();
      } else {
        this.$alert(data.msg, "提示", {
          confirmButtonText: "确定",
          customClass: "messageasd",
          callback: (action) => {},
        });
      }
    },
    async enstate() {
      //判断是否需要支付
      var bbc = this.applyno;
      const { data } = await bhprocess(bbc);
      this.state = data.data.state; //审核状态
      this.statemsg = data.data.statemsg;
      // this.input.jbrname = data.data.payer; //付款人
      this.input.bankname = data.data.rebankname; //付款人
      this.input.bankaccount = data.data.rebankaccount; //付款人
      this.payer = data.data.payer;
      this.jbrphone = data.data.jbrphone;
      this.fpstate = data.data.fpstate; //审核状态
      var that = this;
      if (this.imageUrl == "") {
        return;
      }

      if (this.state == 0) {
        that.tijiao = "下一步";
        this.bajsd = false;
        return;
      }
      if (this.state == 1) {
        that.tijiao = "下一步";
        that.indexs = "审核已完成";
        this.bajsd = false;
      }
    },
    handleAvatarSuccess(res, file) {
      console.log(23);
      this.input.zfimage = file.response.data.fullurl;
      // console.log(file.response.data.fullurl);
      this.imageUrl = file.response.data.fullurl;
    },
    beforeAvatarUpload(file) {
      console.log(123456);
      const isJPG = file.type;
      const isLt2M = file.size;
      return isJPG && isLt2M;
    },

    project() {
      var bbc = this.input.baohanno;
      var cion = this.applyno;
      setInterval(function () {
        const { data } = bhprocess(cion);
        console.log(data);
        // this.$router.push({
        //   name: "public",
        //   params: {
        //     bbc,
        //   },
        // });
      }, 1000);
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
  },
};
</script>

<style scoped>
.asdzong {
  position: fixed;
  right: 20px;
  bottom: 10%;
  width: 200px;
  display: flex;
  border: 1px #c5c5c5 solid;
  border-top: 3px tomato solid;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  padding-bottom: 20px;
}
.asdimgh {
  width: 60px;
  height: 60px;
}
html {
  width: 100vw;
}
.znashi {
  margin-left: 5%;
}
.bacl {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  /* margin-top: 1rem; */
}
.quan {
  /* background: rgb(128, 128, 128, 1); */
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.app {
  /* padding: 0 20%; */
}
. .app-xt {
  padding: 0 8%;
}
.b-bbc {
  /* margin: 0 auto; */
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 0.5rem 0;
  border: 0.0625rem solid #d9d9d9;
  /* padding-right: 4.375rem; */
  margin-bottom: 1.25rem;
}
.inout-1 {
  /* padding: 0.9375rem 0rem; */
  padding-top: 0rem;
  border: 0.0625rem solid #d9d9d9;
}
.indec {
  display: inline-block;
  width: 26.25rem;
  padding: 3.125rem;
  margin-top: -15%;
  padding-bottom: 0.625rem;
  vertical-align: middle;
  background-color: #fff;
  border-radius: 0.25rem;
  border: 0.0625rem solid #ebeef5;
  font-size: 1.125rem;
  box-shadow: 0 0.125rem 0.75rem 0rgba (0, 0, 0, 0.1);
  text-align: left;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  padding-bottom: 1.875rem;
}
/* .csdff{
  width:100%
} */
.name {
  margin-bottom: 1.25rem;
}
.input {
  display: flex;
  /* justify-content: space-around; */
  /* align-items: center; */
  /* padding-right: 3rem; */
}
.name-jin {
  width: 100%;
  height: 100%;
  background: #dedede;
}
.uppder {
  padding-right: 0.75rem;
}
.input-flex {
  display: flex;
  margin-top: 0.625rem;
}
.input-1 {
  width: 90% !important;
  width: 7.0625rem;
  /* height: 1.25rem !important; */
}
.input-1s {
  width: 70% !important;

  /* height: 1.25rem !important; */
}
.app-h {
  padding-left: 27px;
  /* width: 100%; */
  height: 43px;
  line-height: 43px;
  background: #1890ff;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
/deep/ .el-input__inner {
  margin-left: 0.875rem !important;
  padding: 0 !important;
  font-size: 18px !important;
  padding-left: 0.9375rem !important;
  height: 1.6875rem !important;
  line-height: 1.6875rem !important;
  color: #333333 !important;
  width: 90%;
}

.text {
  font-size: 0.875rem;
}

.item {
  padding: 0.625rem 0;
  padding-left: 0.625rem;
}

.box-card {
  width: 30rem;
}
.flex-txt {
  font-size: 0.875rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ff0101;
}
.flex-txt1 {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  width: 38%;
  text-align: left;
}
.abc {
  /* padding-left: 1.5rem; */
}
.app-a {
  display: flex;
  /* margin-top: 28px; */
  position: relative;
}
.a-left {
  width: 50%;
  margin-top: 1px;
  height: 47px;
  display: flex;
  line-height: 50px;
  align-content: center;
  justify-content: center;
  color: #069cff !important;
  /* background: #cdebff; */
  border: 1px solid #069cff;
  /* border-right: 0; */
  /* border-radius: 9px 0px 0px 9px; */
  font-size: 17px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
.a-img {
  padding-top: 13.6px;
  padding-right: 15px;
  width: 20px;
  height: 20px;
}
.aa-img {
  position: absolute;
  height: 3.06rem;
  margin-top: 1px;
  padding: 0;
  left: 45%;
}
.a-right {
  display: flex;
  align-content: center;
  justify-content: center;
  line-height: 50px;
  width: 50%;
  height: 49px;
  margin-top: 0.0625rem;

  background: #069cff;
  /* border-radius: 7px; */
  font-size: 17px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
.app-b {
  font-size: 0.5625rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  /* margin-top: 20px; */
    display: flex;
    flex-direction: column;
  background: #ffffff;
  /* display: flex; */
  /* flex-wrap: wrap; */
  padding: 1% 6%;
  /* align-content: space-around;
  justify-content: space-between; */
}
.app-bbb {
  display: flex;
  justify-content: center;
  align-content: center;
  margin-top: 10%;
}
.app-c {
  width: 100%;
  display: flex;
  /* margin-top: 30px; */
  flex-direction: column;
}
.app-b2 {
  display: flex;
  line-height: 24px;
  font-size: 13px;
  width: 48%;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  margin-bottom: 26px;
}
.b-txt {
  width: 296px;
  height: 24px;
  flex: 1;
  background: #f3f3f3;
  border: 1px solid #dedede;
  padding-left: 6px;
  line-height: 24px;
  font-size: 0.8125rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
}
.c-img {
  width: 30%;
  height: 100%;
}
.c-txt {
  font-size: 1.2rem;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
  margin-bottom: 10.6px;
}
.txt-1 {
  font-size: 13px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
.txt-2 {
  margin-top: 17px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
.txt-3 {
  margin-top: 12px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
.txt-4 {
  margin-top: 15px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  display: flex;
}
.c-div {
  margin-top: 4px;
  display: flex;
  flex-wrap: wrap;
  align-content: space-around;
  justify-content: space-between;
}
.butt {
  width: 100%;
  display: flex;
  justify-content: center;
}
.button-i {
  /* margin-top: 48px; */
  margin-bottom: 10px;
  width: 24%;
  height: 5.5%;
  padding: 6px 0;
  line-height: 30px;
  /* border-radius: 10px; */
  background: #069cff;
  text-align: center;
  font-size: 17px;
  font-family: Microsoft YaHei;
  font-weight: 550;
  color: #fefefe;
}
.b-bbc {
  /* padding: .625rem;
  border:.0625rem solid #dfdcdc; */
}
/* 上传 */
.avatar-uploader .el-upload {
  border: 0.0625rem dashed #d9d9d9;
  border-radius: 0.375rem;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader {
  border: 0.0625rem solid #d9d9d9;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 1.75rem;
  color: #8c939d;
  width: 24.125rem;
  height: 15.125rem;
  line-height: 15.125rem;
  text-align: center;
}
.el-icon-circle-close {
  height: 11.125rem !important;
}
.avatar {
  width: 24.125rem;
  height: 15.125rem;
  /* object-fit: cover; */
  display: block;
}
.text {
  font-size: 14px;
}

.item {
  padding: 0.325rem 0;
  font-size: 18px;
  padding-left: 1.025rem;
}

.box-card {
  width: 480px;
}
</style>
<style >
.messageasd {
  width: 267px !important;
  margin-top: -15.875rem;
}
.el-message-box__btns {
  padding: 5px 15px 0;
  text-align: center !important;
}
</style>