import request from "@/utils/request"

// 获取对公识别码以及对公账户
export const msgcode = (params) => {
    return request({
        method: "get",
        params: params,
        url: "/QBaoHan/payinfo",
    });
};
// 获取收款账户信息
// export const getpayinfo = (params) => {
//     return request({
//         method: "POST",
//         data: params,
//         url: "/handle/Baohan/getpayinfo",
//     });
// };
// // 支付信息提交
// export const bhpay = (params) => {
//     return request({
//         method: "POST",
//         data: params,
//         url: "/handle/Baohan/bhpay",
//     });
// };
// // 获取对公识别码
// // export const msgcode = (params) => {
// //     return request({
// //         method: "POST",
// //         data: params,
// //         url: "/tripartite/mobile/xbhan/msgcode",
// //     });
// // };
// // 获取对公识别码以及对公账户
// export const msgcode = (params) => {
//     return request({
//         method: "POST",
//         data: params,
//         url: "/tripartite/mobile/pay/payinfo",
//     });
// };